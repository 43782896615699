import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export default memo(function Error404() {
  return (
    <Container>
      <Wrapper>
        <Logo
          src="logo.png"
          alt="Hotel Adler Cavalieri"
          width="186"
          height="140"
        />
        <Title>Pagina Non Trovata</Title>
        <Text>Spiacenti, non siamo riusciti a trovare questa pagina!</Text>
        <CTA label={useVocabularyData('back-to-home', 'it-IT')} URL="/" />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  height: 8.75rem;
  margin-bottom: 3rem;
  svg {
    width: auto;
    height: 100%;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
  }

  @media (max-width: 571px) {
    font-size: 2.25rem;
  }
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  line-height: 2rem;
  opacity: 0.5;
  margin-top: 1rem;
`

const CTA = styled(Button)`
  margin-top: 1.875rem;
  &:hover {
    background: none;
  }
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
`
